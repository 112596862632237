import React from "react"
import { graphql } from "gatsby"

import SEO from "../components/seo"
import Layout from "../components/layout"
import FourchetteIcon from "../components/svg/fourchette-icon"
import LocalizedLink from "../components/localizedLink"

import {FadeInUp} from "../components/animations/fadeInUp"

class NotFoundPage extends React.Component {

  render() {
    const { data, pageContext: { locale } } = this.props
    const content = data.file.childContentJson.global.no_content

    return (
      <Layout {...this.props} className="u-height-full">
        <SEO title="404: Not found" />

        <section className="u-pd-vt-xxl u-fadeInUp">
          <div className="l-container u-pd-hz-l">

            <FadeInUp yOffset={50} delay={100}>
              <h1 className="c-h1 u-green80 u-uppercase u-semibold u-flex u-flex-center-vt u-mg-bottom-m">
                <FourchetteIcon className="u-mg-right-l" style={{ width: "60px", height: "15px" }}/>
                {content.title}
              </h1>
            </FadeInUp>

            <FadeInUp yOffset={75} delay={200}>
              <p className="u-fs-m u-lh-l u-mg-bottom-l" style={{ maxWidth: "960px"}}>{content.message}</p>
            </FadeInUp>

            <FadeInUp yOffset={100} delay={300}>
              <div className="u-center">
                <LocalizedLink to={'/'} className="c-btn c-btn--primary" style={{ minWidth: "240px"}}>
                    <span>{content.cta}</span>
                    <span>{content.cta_hover}</span>
                </LocalizedLink>
              </div>
            </FadeInUp>

          </div>
        </section>

      </Layout>
    )
  }
}

export default NotFoundPage

export const pageQuery = graphql`
  query ($locale: String) {
    file(relativeDirectory: { eq: $locale }) {
      childContentJson {
        global {
          header {
            candidate
            lang {
              en
              fr
            }
            navigation {
              accelerator
              english
              blog
              team
              program
              thematic
            }
          }
          footer {
            by
            cgu
            contact
            email
            press
            social {
              facebook
              instagram
              title
              twitter
              linkedin
            }
          }
          no_content {
            title
            message
            cta
            cta_hover
          }
          partners {
            name
            image
            link
          }
        }
      }
    },
    partnersLogo: allFile(
      filter: { absolutePath: { regex: "//partners//" } }
    ) {
      nodes {
        name
        childImageSharp {
          fixed(height: 60, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  }
`
